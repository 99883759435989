import React, { Component } from 'react';
import i18next from '../i18n';
import {
    Navbar,
    NavbarBrand,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.langObj = {
            "it": 'Italiano',
            "en": 'English',
            "es": 'Espanol',
            "fr": 'Francais',
        };

        this.state = {
            language: i18next.language
        };
    }

    handleLangUpdate = (e, lang) => {
        e.preventDefault();
        i18next.changeLanguage(lang);
        this.setState({
            language: lang
        });
    }

    render() {
        return (
            <header>
                <Navbar color="light" light className="border-bottom box-shadow mb-3 py-0">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <NavbarBrand tag={Link} to="http://www.aruba.it" className="p-0">
                            <img src="./aruba2.png" alt="Aruba" height="30" />
                        </NavbarBrand>
                        <UncontrolledDropdown>
                            <DropdownToggle nav caret className="p-0 d-flex align-items-center">
                                <img src="./language.png" alt="language" height="20" className="mr-1" />
                                <span className="selected-language">{this.langObj[this.state.language]}</span>
                            </DropdownToggle>
                            <DropdownMenu right>
                                {Object.entries(this.langObj).map(([code, name]) => (
                                    <DropdownItem key={code} onClick={e => this.handleLangUpdate(e, code)}>
                                        <span className="ms-1">{name}</span>
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </Navbar>
                <div className="gray-line"></div>
                <br/>
            </header>
        );
    }
}
import React, { Component } from 'react';
import i18next from '../i18n';
import ReactStars from 'react-stars';
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import LFStarRating from './LFStarRating';

export default class Home extends Component {


    constructor(props) {
        super(props);

        this.parametri = global.config.parameters;

        this.state = {
            loading: true,
            id: this.parametri.id === null ? 0 : this.parametri.id,
            idTicket: this.parametri.idTicket === null ? 0 : this.parametri.idTicket,
            result: null,
            authenticated: false,
            rating: 0,
            feedback: ''
        }

        this.langObj = {
            "en": 'English',
            "de": 'Deutsch',
            "fr": 'Francais',
            "pt": 'Portughes',
            "it": 'Italiano',
            "es": 'Espanol'
        };


        // NOTA: VALORIZZARE QUESTA  VARIABILE SOLO PER TEST:
        // **************************************************
        // AD ESEMPIO this.lang = 'fr'; PER VEDERE IL PORTALE IN FRANCESE
        // ANCHE SE LA LINGUA DEL BROWSER E' DIVERSA (ITALIANO))
        //
        // IMPOSTARLA A '' PER AVERE LA LINGUA DEL BROWSER
        this.lang = '';


    }

    componentDidMount() {
        this.getAuth();
    }

    renderResult(ritorno) {

        // NOTA BENE: QUESTE VANNO REMMATE PRIMA DI PUBBLICARE !!!
        //******************************************************* */
        //ritorno = 4; // FEEDBACK SU PROBLEMA RISOLTO
        //ritorno = 10; // FEEDBACK SU CONFERMA SOLUZIONE RICHIESTA
        //ritorno = 6; // FEEDBACK POSITIVO ( TRUSTPILOT )

        switch (ritorno) {
            case 0:                         //MISSING PARAMETERES (LINK NON VALIDO)
                return <div className="container text-centered" >
                    <div className="row">
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{ textAlign: "left", paddingRight: "0px", paddingLeft: "0px" }}>
                            <div className="row">
                                <div className="col-1">
                                    <img src="./document.png" alt="documento" />
                                </div>
                                <div className="col-11">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 style={{ color: "#666666", fontSize: "17px", marginLeft: "10px" }}>{i18next.t('eTopRequest')}</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 style={{ marginLeft: "10px", marginBottom: "0px" }}>&nbsp;</h4>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <hr />
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div>
                    <div className="row" style={{ height: "200px" }}>
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{
                            textAlign: "center",
                            background: "#FAE1E4",
                            border: "1px solid #DDDDDD",
                            borderRadius: "4px",
                            opacity: "1"
                        }}>
                            <img src="./alert.png" style={{ paddingTop: "30px" }} alt="testo" /><br /><br />
                            <h2 style={{ fontSize: "24px", color: "#222222" }}>{i18next.t('eMissingParameterTitle')}</h2>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div>
                    <div className="row" style={{ textAlign: "center" }}>
                        <div className="col-12">
                            <br />
                            <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eMissingParameterDescription')}</h3>
                            <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eMissingParameterDescription2')}</h3>
                        </div>
                    </div><br /><br />
                    <div className="row">
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                            <hr></hr>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div>
                    <div className="row" style={{ textAlign: "center" }} >
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6">
                            <h3 style={{ fontSize: "22px", color: "#222222", opacity: "1", paddingTop: "20px" }}>{i18next.t('eHelp')}</h3>
                            <h4 style={{ fontSize: "16px", color: "#222222", opacity: "1", paddingTop: "0px" }}>{i18next.t('eAssistenza')}</h4>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div><br />
                    <div className="row" style={{ textAlign: "center" }} >
                        <div className="col-12">
                            <a className="btn myBtn" href={this.state.authenticated ? "http://assistenzaclienti.aruba.it" : "http://assistenza.aruba.it"} target="_blank" rel="noreferrer">{i18next.t('eBtnPortal')}</a>
                        </div>
                    </div>
                </div>                  //MISSING PARAMETERES (LINK NON VALIDO)
            case 1:                         //TICKET CLOSED
                return <div className="container text-centered" >
                    <div className="row">
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{ textAlign: "left", paddingRight: "0px", paddingLeft: "0px" }}>
                            <div className="row">
                                <div className="col-1">
                                    <img src="./document.png" alt="documento" />
                                </div>
                                <div className="col-11">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 style={{ color: "#666666", fontSize: "17px", marginLeft: "10px" }}>{i18next.t('eTopRequest')}</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 style={{ marginLeft: "10px", marginBottom: "0px" }}>{this.state.idTicket}</h4>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <hr />
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>


                    </div>
                    <div className="row" style={{ height: "200px" }}>
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{
                            textAlign: "center",
                            background: "#FAE1E4",
                            border: "1px solid #DDDDDD",
                            borderRadius: "4px",
                            opacity: "1"
                        }}>
                            <img src="./alert.png" style={{ paddingTop: "30px" }} alt="testo" /><br /><br />
                            <h2 style={{ fontSize: "24px", color: "#222222" }}>{i18next.t('eClosedTitle')}</h2>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div>
                    <div className="row" style={{ textAlign: "center" }}>
                        <div className="col-12">

                            <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >&nbsp;</h3>
                        </div>
                    </div><br /><br />
                    <div className="row">
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                            <hr></hr>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div>
                    <div className="row" style={{ textAlign: "center" }} >
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6">
                            <h3 style={{ fontSize: "18px", color: "#222222", opacity: "1", paddingTop: "20px" }}>{i18next.t('eHelp')}</h3>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div><br />
                    <div className="row" style={{ textAlign: "center" }} >
                        <div className="col-12">
                            <a className="btn myBtn" href={this.state.authenticated ? "http://assistenzaclienti.aruba.it" : "http://assistenza.aruba.it"} target="_blank" rel="noreferrer">{i18next.t('eBtnPortal')}</a>
                        </div>
                    </div>
                </div>                  //TICKET CLOSED (ROSSO)
            case 2:
                //RICHIESTA IN LAVORAZIONE
                return <div className="container text-centered" >
                    <div className="row">
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{ textAlign: "left", paddingRight: "0px", paddingLeft: "0px" }}>
                            <div className="row">
                                <div className="col-1">
                                    <img src="./document.png" alt="documento" />
                                </div>
                                <div className="col-11">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 style={{ color: "#666666", fontSize: "17px", marginLeft: "10px" }}>{i18next.t('eTopRequest')}</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 style={{ marginLeft: "10px", marginBottom: "0px" }}>{this.state.idTicket}</h4>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <hr />
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>


                    </div>
                    <div className="row" style={{ height: "200px" }}>
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{
                            textAlign: "center",
                            background: "#FAE1E4",
                            border: "1px solid #DDDDDD",
                            borderRadius: "4px",
                            opacity: "1"
                        }}>
                            <img src="./alert.png" style={{ paddingTop: "30px" }} alt="testo" /><br /><br />
                            <h2 style={{ fontSize: "24px", color: "#222222" }}>{i18next.t('eLavorazioneTitle')}</h2>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div>

                    <div className="row" style={{ textAlign: "center" }}>

                        {

                            this.state.authenticated ?
                                <div className="col-12">
                                    <br />

                                    {/*<h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('workingOnTicketNotAuthenticatedDescription')}</h3>*/}

                                    <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('workingOnTicketNotAuthenticatedDescription')}</h3>
                                    <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('workingOnTicketNotAuthenticatedDescription2')}<a href={"https://assistenzaclienti.aruba.it/CustomerPortal/Aruba/ViewTicket/" + this.state.idTicket}>{i18next.t('ePortal')}</a>,</h3>
                                    <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('workingOnTicketNotAuthenticatedDescription3')}</h3>
                                    <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('workingOnTicketNotAuthenticatedDescription4')}</h3>

                                </div>
                                :
                                <div className="col-12">
                                    <br />
                                    {/*<h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eLavorazioneMsg1')}</h3>*/}
                                    {/*<h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eLavorazioneMsg2')}<a href={"https://assistenzaclienti.aruba.it/CustomerPortal/Aruba/ViewTicket/" + this.state.idTicket}>{i18next.t('ePortal')}</a>,</h3>*/}
                                    {/*<h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eLavorazioneMsg3')}</h3>*/}

                                    <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eLavorazioneMsg1')}</h3>
                                    <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eLavorazioneMsg2')}</h3>
                                    <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eLavorazioneMsg3')}</h3>

                                </div>

                        }

                    </div><br /><br />
                    <div className="row">
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                            <hr></hr>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div>
                    <div className="row" style={{ textAlign: "center" }} >
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6">
                            <h3 style={{ fontSize: "18px", color: "#222222", opacity: "1", paddingTop: "20px" }}>{i18next.t('eHelp')}</h3>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div><br />
                    <div className="row" style={{ textAlign: "center" }} >
                        <div className="col-12">
                            <a className="btn myBtn" href={this.state.authenticated ? "http://assistenzaclienti.aruba.it" : "http://assistenza.aruba.it"} target="_blank" rel="noreferrer">{i18next.t('eBtnPortal')}</a>
                        </div>
                    </div>
                </div>                  //RICHIESTA ANCORA IN LAVORAZIONE
            case 3:                     //RICHIESTA RISOLTA    
                return <div className="container text-centered" >
                    <div className="row">
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{ textAlign: "left", paddingRight: "0px", paddingLeft: "0px" }}>
                            <div className="row">
                                <div className="col-1">
                                    <img src="./document.png" alt="documento" />
                                </div>
                                <div className="col-11">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 style={{ color: "#666666", fontSize: "17px", marginLeft: "10px" }}>{i18next.t('eTopRequest')}</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 style={{ marginLeft: "10px", marginBottom: "0px" }}>{this.state.idTicket}</h4>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <hr />
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>


                    </div>
                    <div className="row" style={{ height: "200px" }}>
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{
                            textAlign: "center",
                            background: "#DBF2DC",
                            border: "1px solid #DDDDDD",
                            borderRadius: "4px",
                            opacity: "1"
                        }}>
                            <img src="./success.png" style={{ paddingTop: "30px" }} alt="testo" /><br /><br />
                            <h2 style={{ fontSize: "24px", color: "#222222" }}>{i18next.t('eRequest')}&nbsp;{i18next.t('eSolved')}</h2>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div>
                    <br /><br /> <br /><br />

                    <div className="row">
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                            <hr></hr>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div>
                    <div className="row" style={{ textAlign: "center" }} >
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6">
                            <h3 style={{ fontSize: "18px", color: "#222222", opacity: "1", paddingTop: "20px" }}>{i18next.t('eHelp')}</h3>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div><br />
                    <div className="row" style={{ textAlign: "center" }} >
                        <div className="col-12">
                            <a className="btn myBtn" href={this.state.authenticated ? "http://assistenzaclienti.aruba.it" : "http://assistenza.aruba.it"} target="_blank" rel="noreferrer">{i18next.t('eBtnPortal')}</a>
                        </div>
                    </div>
                </div>                  //RICHIESTA RISOLTA   
            case 4:                     //FEEDBACK
                return <div className="container text-centered">
                    <div className="row">
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{ textAlign: "left", paddingRight: "0px", paddingLeft: "0px" }}>
                            <div className="row">
                                <div className="col-1">
                                    <img src="./document.png" alt="documento" />
                                </div>
                                <div className="col-11">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 style={{ color: "#666666", fontSize: "17px", marginLeft: "10px" }}>{i18next.t('eTopRequest')}</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 style={{ marginLeft: "10px", marginBottom: "0px" }}>{this.state.idTicket}</h4>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <hr />
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>


                    </div>
                    <div className="row" style={{ height: "200px" }}>
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{
                            textAlign: "center",
                            background: "#DBF2DC",
                            border: "1px solid #DDDDDD",
                            borderRadius: "4px",
                            opacity: "1"
                        }}>
                            <img src="./success.png" style={{ paddingTop: "30px" }} alt="testo" /><br /><br />
                            <h2 style={{ fontSize: "24px", color: "#222222" }}>{i18next.t('eRequest')}&nbsp;{i18next.t('eSolved2')}</h2>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div>

                    <div className="row" style={{ textAlign: "center" }}>

                        <div className="col-12">

                        <br/>
                            <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eValuti')}</h3>
                            <div className="" style={{ textAlign: "center" }}>
                                <div className="row">

                                    <div className="col-1">
                                        &nbsp;
                                    </div>
                                    <div className="col-10">
                                        {/*<ReactStars style={{ textAlign: "center" }}*/}
                                        {/*    count={5}*/}
                                        {/*    value={this.state.rating}*/}
                                        {/*    onChange={this.ratingChanged}*/}
                                        {/*    size={30}*/}
                                        {/*    half={false}*/}
                                        {/*    color2={'#ffd700'} />*/}

                                        <LFStarRating
                                            onChange={this.ratingChanged}
                                            initialRating={this.state.rating}
                                            size={40}
                                            color="#ffd700"
                                            unselectedColor="#d3d3d3"
                                            starSpacing={20} // Modifica questo valore per aumentare o diminuire lo spazio tra le stelle
                                        />
                                    </div>
                                    <div className="col-1">
                                        &nbsp;
                                    </div>

                                </div>
                            </div>
                            <br />
                            {(this.state.rating === 0 || this.state.rating > 3) ? (

                                null

                            ) : <div>
                                <h5 style={{ color: "#666666", fontSize: "17px", marginLeft: "10px" }}>{i18next.t('eOppinione')}</h5>
                                <textarea placeholder='' style={{ width: "320px" }} rows="5" onChange={this.feedbackChanged}></textarea>
                            </div>}
                        </div>

                    </div><br /><br />


                    <div className="row" style={{ textAlign: "center" }} >

                        <div className="col-12">
                            <Button className='myBtnInverse' style={{ color: "white" }} tag={Link} onClick={this.sendFeedBack}>
                                {i18next.t('eBtnSend')}
                            </Button>

                        </div>

                    </div> <br /><br />
                    <br /><br />
                </div>                  //FEEDBACK
            case 5:                     //FEEDBACK NEGATIVO
                return <div className="container text-centered" >
                    <div className="row" style={{ height: "200px" }}>
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{
                            textAlign: "center",
                            background: "#DBF2DC",
                            border: "1px solid #DDDDDD",
                            borderRadius: "4px",
                            opacity: "1"
                        }}>
                            <img src="./success.png" style={{ paddingTop: "30px" }} alt="testo" /><br /><br />
                            <h2 style={{ fontSize: "24px", color: "#222222" }}>{i18next.t('ticketClosedCorrectly')}</h2>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div><br />
                    <div className="row" style={{ textAlign: "center" }}>
                        {
                            this.state.rating === 0 ?
                                <span></span>
                                : this.state.rating <= 3
                                    ?
                                    <div className="col-12">

                                        <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eFeedBack')}</h3>
                                        <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eNegativeFeedback1')}</h3>
                                        <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eNegativeFeedback2')}</h3>
                                    </div>
                                    :
                                    <div className="col-12">

                                        <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eFeedBack')}</h3>
                                        <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('ePositiveFeedback')}</h3>
                                    </div>
                        }
                    </div><br /><br />
                    <div className="row">
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                            <hr></hr>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div>
                    <div className="row" style={{ textAlign: "center" }} >
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6">
                            <h3 style={{ fontSize: "18px", color: "#222222", opacity: "1", paddingTop: "20px" }}>{i18next.t('eHelp')}</h3>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div><br />
                    <div className="row" style={{ textAlign: "center" }} >
                        <div className="col-12">
                            <a className="btn myBtn" href={this.state.authenticated ? "http://assistenzaclienti.aruba.it" : "http://assistenza.aruba.it"} target="_blank" rel="noreferrer">{i18next.t('eBtnPortal')}</a>
                        </div>
                    </div>
                </div>                  //FEEDBACK NEGATIVO
            case 6:                     //FEEDBACK POSITIVO
                return <div className="container text-centered" >
                    <div className="row" style={{ height: "200px" }}>
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{
                            textAlign: "center",
                            background: "#DBF2DC",
                            border: "1px solid #DDDDDD",
                            borderRadius: "4px",
                            opacity: "1"
                        }}>
                            <img src="./success.png" style={{ paddingTop: "30px" }} alt="testo" /><br /><br />
                            <h2 style={{ fontSize: "24px", color: "#222222" }}>{i18next.t('ticketClosedCorrectly')}</h2>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div><br />
                    <div className="row" style={{ textAlign: "center" }}>
                        <div className="col-12">
                            <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eFeedBack')}</h3>
                            {/*<h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('ePositiveFeedback')}</h3>*/}
                            {/*<h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('ePositiveFeedback1')}</h3>*/}
                            <br />
                            <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eTrustPilotMsg')}</h3>
                            <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eTrustPilotMsg1')}</h3>

                            <button
                                className="btn myBtnInverse"
                                onClick={(e) => {
                                    //this.sendFeedBack(e);
                                    window.open(i18next.t('eTrustPilotBtnLnk'), '_blank', 'noopener,noreferrer');
                                }}
                            >
                                {i18next.t('eTrustPilotBtnMsg')}
                            </button>




                        </div>
                    </div><br />
                    <div className="row">
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                            <hr></hr>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div>
                    <div className="row" style={{ textAlign: "center" }} >
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6">
                            <h3 style={{ fontSize: "18px", color: "#222222", opacity: "1", paddingTop: "20px" }}>{i18next.t('eHelp')}</h3>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div><br />
                    <div className="row" style={{ textAlign: "center" }} >
                        <div className="col-12">
                            <a className="btn myBtn" href={this.state.authenticated ? "http://assistenzaclienti.aruba.it" : "http://assistenza.aruba.it"} target="_blank" rel="noreferrer">{i18next.t('eBtnPortal')}</a>
                        </div>
                    </div>
                </div>                  //FEEDBACK POSITIVO    
            case 7:                         //Ulteriori comunicazioni (tipo  LINK NON PIU' VALIDO ...)
                return <div className="container text-centered" >
                    <div className="row">
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{ textAlign: "left", paddingRight: "0px", paddingLeft: "0px" }}>
                            <div className="row">
                                <div className="col-1">
                                    <img src="./document.png" alt="documento" />
                                </div>
                                <div className="col-11">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 style={{ color: "#666666", fontSize: "17px", marginLeft: "10px" }}>{i18next.t('eTopRequest')}</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 style={{ marginLeft: "10px", marginBottom: "0px" }}>{this.state.idTicket}</h4>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <hr />
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>


                    </div>
                    <div className="row" style={{ height: "200px" }}>
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{
                            textAlign: "center",
                            background: "#FDF5E8",
                            border: "1px solid #DDDDDD",
                            borderRadius: "4px",
                            opacity: "1"
                        }}>
                            <img src="./warning.png" style={{ paddingTop: "30px" }} alt="testo" /><br /><br />
                            <h2 style={{ fontSize: "24px", color: "#222222" }}>{i18next.t('eOtherComunication')}</h2>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div>
                    <div className="row" style={{ textAlign: "center" }}>
                        <div className="col-12">
                            <br />

                            {/*{this.state.authenticated*/}
                            {/*{    ? } //autenticato*/}
                            {/*      <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }}>{i18next.t('eOtherComunicationMsg0')}</h3>*/}
                            {/*      <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }}>{i18next.t('eOtherComunicationMsg1')}<a target="_blank" rel="noreferrer" href={"https://assistenzaclienti.aruba.it/CustomerPortal/Aruba/ViewTicket/" + this.state.idTicket}>{i18next.t('ePortal')}</a>&nbsp;{i18next.t('eOtherComunicationMsg2')}</h3>*/}

                            {/*{    : } //non autenticato*/}
                            {/*     <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eOtherComunicationMsg6')}</h3>*/}

                            {/*}*/}

                            {this.state.authenticated
                                ? (
                                    <>
                                        <h3 style={{ fontSize: "17px", color: "#222222", fontWeight: "bold", opacity: "1" }}>
                                            {i18next.t('eOtherComunicationMsg0')}
                                        </h3>
                                        <h3 style={{ fontSize: "17px", color: "#222222", fontWeight: "bold", opacity: "1" }}>
                                            {i18next.t('eOtherComunicationMsg1')}
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href={`https://assistenzaclienti.aruba.it/CustomerPortal/Aruba/ViewTicket/${this.state.idTicket}`}
                                            >
                                                {i18next.t('ePortal')}
                                            </a>
                                            &nbsp;{i18next.t('eOtherComunicationMsg2')}
                                        </h3>
                                    </>
                                )
                                : (
                                    <h3 style={{ fontSize: "17px", color: "#222222", fontWeight: "bold", opacity: "1" }}>
                                        {i18next.t('eOtherComunicationMsg6')}
                                    </h3>
                                )
                            }


                        </div>
                    </div><br /><br />
                    <div className="row">
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                            <hr></hr>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div>
                    <div className="row" style={{ textAlign: "center" }} >
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6">
                            <h3 style={{ fontSize: "18px", color: "#222222", opacity: "1", paddingTop: "20px" }}>{i18next.t('eHelp')}</h3>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div><br />
                    <div className="row" style={{ textAlign: "center" }} >

                        <div className="col-12">
                            <a className="btn myBtn" href={this.state.authenticated ? "http://assistenzaclienti.aruba.it" : "http://assistenza.aruba.it"} target="_blank" rel="noreferrer">{i18next.t('eBtnPortal')}</a>
                        </div>

                    </div><br />
                </div>                  //ULTERIORI COMUNICAZIONI   
            case 8:                     //Chiusura anticipata
                return <div className="container text-centered">
                    <div className="row">
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{ textAlign: "left", paddingRight: "0px", paddingLeft: "0px" }}>
                            <div className="row">
                                <div className="col-1">
                                    <img src="./document.png" alt="documento" />
                                </div>
                                <div className="col-11">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 style={{ color: "#666666", fontSize: "17px", marginLeft: "10px" }}>{i18next.t('eTopRequest')}</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 style={{ marginLeft: "10px", marginBottom: "0px" }}>{this.state.idTicket}</h4>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <hr />
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>


                    </div>
                    <br />

                    <div className="row" style={{ textAlign: "center" }}>

                        <div className="col-12">

                            <br />
                            <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eAnticipata')}</h3>
                            <h4 style={{ fontSize: "14px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eSelAnticipata')}</h4>

                            <br />

                        </div>

                    </div>
                    <div className="row" style={{ textAlign: "center" }}>
                        <div className="col-2">
                            &nbsp;
                        </div>
                        <div className="col-8">
                            <div className="row" style={{ textAlign: "left" }}>
                                <div className="col-4" style={{ fontSize: "14px", color: "#222222", fontStyle: "bold", opacity: "1", textAlign: "right" }}>
                                    <input type="radio" value={i18next.t('eMot1')} name="motivo" onClick={this.setmotivo} /> {i18next.t('eMot1')}
                                </div>
                                <div className="col-4" style={{ fontSize: "14px", color: "#222222", fontStyle: "bold", opacity: "1", textAlign: "center" }}>
                                    <input type="radio" value={i18next.t('eMot2')} name="motivo" onClick={this.setmotivo} /> {i18next.t('eMot2')}
                                </div>
                                <div className="col-4" style={{ fontSize: "14px", color: "#222222", fontStyle: "bold", opacity: "1" }}>
                                    <input type="radio" value={i18next.t('eMot3')} name="motivo" onClick={this.setmotivo} /> {i18next.t('eMot3')}
                                </div>
                            </div>
                        </div>
                        <div className="col-2">
                            &nbsp;
                        </div>
                    </div><br />
                    <div className="row" style={{ textAlign: "center" }}>
                        <div className="col-2">
                            &nbsp;
                        </div>
                        <div className="col-8">
                            <div className="row" style={{ textAlign: "left" }}>
                                <div className="col-4" style={{ fontSize: "14px", color: "#222222", fontStyle: "bold", opacity: "1", textAlign: "right" }}>
                                    <input type="radio" value={i18next.t('eMot4')} name="motivo" onClick={this.setmotivo} /> {i18next.t('eMot4')}
                                </div>
                                <div className="col-4" style={{ fontSize: "14px", color: "#222222", fontStyle: "bold", opacity: "1", textAlign: "center" }}>
                                    <input type="radio" value={i18next.t('eMot5')} name="motivo" onClick={this.setmotivo} /> {i18next.t('eMot5')}
                                </div>
                                <div className="col-4" style={{ fontSize: "14px", color: "#222222", fontStyle: "bold", opacity: "1" }}>
                                    <input type="radio" value={i18next.t('eMot6')} name="motivo" onClick={this.setmotivo} /> {i18next.t('eMot6')}
                                </div>
                            </div>
                        </div>
                        <div className="col-2">
                            &nbsp;
                        </div>
                    </div><br />


                    <div className="row" style={{ textAlign: "center" }} >

                        <div className="col-12">
                            <Button className='myBtnInverse2' style={{ color: "white" }} tag={Link} onClick={this.anticipa}>
                                {i18next.t('eAnticipataBtn')}
                            </Button>

                        </div>

                    </div> <br /><br />
                    <br /><br />
                </div>                  //CHIUSURA ANTICIPATA
            case 9:                     //COMUNICATION ERROR
                return <div className="container text-centered" >
                    <div className="row">
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{ textAlign: "left", paddingRight: "0px", paddingLeft: "0px" }}>
                            <div className="row">
                                <div className="col-1">
                                    <img src="./document.png" alt="documento" />
                                </div>
                                <div className="col-11">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 style={{ color: "#666666", fontSize: "17px", marginLeft: "10px" }}>{i18next.t('eTopRequest')}</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 style={{ marginLeft: "10px", marginBottom: "0px" }}>{this.state.idTicket}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <hr />
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>


                    </div>
                    <div className="row" style={{ height: "200px" }}>
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{
                            textAlign: "center",
                            background: "#FAE1E4",
                            border: "1px solid #DDDDDD",
                            borderRadius: "4px",
                            opacity: "1"
                        }}>
                            <img src="./alert.png" style={{ paddingTop: "30px" }} alt="testo" /><br /><br />
                            <h2 style={{ fontSize: "24px", color: "#222222" }}>{i18next.t('eComunicationError')}</h2>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div>
                    <div className="row" style={{ textAlign: "center" }}>
                        <div className="col-12">
                            <br />
                            {/*                <h3 style={{ fontSize: "17px", color: "#222222" }}>{i18next.t('eComunicationError')}</h3>*/}
                            <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eComunicationErrorMsg')}</h3>
                        </div>
                    </div><br /><br />
                    <div className="row">
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                            &nbsp;
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div>
                    <div className="row" style={{ textAlign: "center" }} >
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6">
                            &nbsp;
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div><br />
                    <div className="row" style={{ textAlign: "center" }} >
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6">
                            &nbsp;
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div>
                </div>                  //COMUNICATION ERROR  
            case 10:                    //CHIUSURA RICHIESTA
                return <div className="container text-centered" >
                    <div className="row">
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{ textAlign: "left", paddingRight: "0px", paddingLeft: "0px" }}>
                            <div className="row">
                                <div className="col-1">
                                    <img src="./document.png" alt="documento" />
                                </div>
                                <div className="col-11">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 style={{ color: "#666666", fontSize: "17px", marginLeft: "10px" }}>{i18next.t('eTopRequest')}</h5>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <h4 style={{ marginLeft: "10px", marginBottom: "0px" }}>{this.state.idTicket}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <hr />
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>


                    </div>
                    <br /><br />

                    <div className="row" style={{ textAlign: "center" }}>
                        <div className="col-12">

                            <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eConfirmClose')}</h3>
                        </div>
                        <div className="col-12">
                            <br />
                            <h3 style={{ fontSize: "15px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eConfirmCloseMsg')}</h3>
                            <h3 style={{ fontSize: "15px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eConfirmCloseMsg1')}</h3>
                        </div>
                    </div>
                    <div className="row" style={{ textAlign: "center" }}>
                        <div className="col-12">
                            <div className="" style={{ textAlign: "center" }}>
                            <br/>
                                <div className="row">

                                    <div className="col-1">
                                        &nbsp;
                                    </div>
                                    <div className="col-10">

                                        {/*<ReactStars style={{ textAlign: "center" }}*/}
                                        {/*    count={5}*/}
                                        {/*    value={this.state.rating}*/}
                                        {/*    onChange={this.ratingChanged}*/}
                                        {/*    size={40}*/}
                                        {/*    half={false}*/}
                                        {/*    color2={'#ffd700'} />*/}

                                        <LFStarRating
                                            onChange={this.ratingChanged}
                                            initialRating={this.state.rating}
                                            size={40}
                                            color="#ffd700"
                                            unselectedColor="#d3d3d3"
                                            starSpacing={20} // Modifica questo valore per aumentare o diminuire lo spazio tra le stelle
                                        />
                                    </div>
                                    <div className="col-1">
                                        &nbsp;
                                    </div>

                                </div>
                            </div>
                            <br />
                            {(this.state.rating === 0 || this.state.rating > 3) ? (
                                null
                            ) : <div>
                                <div className="col-12">
                                    <br />
                                        <h3 style={{ fontSize: "15px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eOppinione')}</h3>
                                        <h3 style={{ fontSize: "15px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eOppinione1')}</h3>
                                </div>
                                <div className="col-12">
                                    &nbsp;
                                </div>
                                <textarea placeholder='' style={{ width: "320px" }} rows="5" onChange={this.feedbackChanged}></textarea>
                            </div>}
                        </div>

                    </div><br /><br />

                    <div className="row" style={{ textAlign: "center" }} >

                        <div className="col-12">
                            <Button className='myBtnInverse2' style={{ color: "white" }} tag={Link} onClick={this.confirmClose}>
                                {i18next.t('eConfirmBtn')}
                            </Button>

                        </div>
                    </div>

                </div>                 //COMUNICATION ERROR  
            case 11:                    //TICKET CLOSED (VERDE)
                return <div className="container text-centered" >
                    <div className="row">
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{ textAlign: "left", paddingRight: "0px", paddingLeft: "0px" }}>
                            <div className="row">
                                <div className="col-1">
                                    <img src="./document.png" alt="documento" />
                                </div>
                                <div className="col-11">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 style={{ color: "#666666", fontSize: "17px", marginLeft: "10px" }}>{i18next.t('eTopRequest')}</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 style={{ marginLeft: "10px", marginBottom: "0px" }}>{this.state.idTicket}</h4>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <hr />
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>


                    </div>
                    <div className="row" style={{ height: "200px" }}>
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{
                            textAlign: "center",
                            background: "#DBF2DC",
                            border: "1px solid #DDDDDD",
                            borderRadius: "4px",
                            opacity: "1"
                        }}>
                            <img src="./success.png" style={{ paddingTop: "30px" }} alt="testo" /><br /><br />
                            <h2 style={{ fontSize: "24px", color: "#222222" }}>{i18next.t('ticketClosedCorrectly')}</h2>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div>
                    <div className="row" style={{ textAlign: "center" }}>
                        <div className="col-12">

                            <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >&nbsp;</h3>
                        </div>
                    </div><br /><br />
                    <div className="row">
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                            <hr></hr>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div>
                    <div className="row" style={{ textAlign: "center" }} >
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6">
                            <h3 style={{ fontSize: "18px", color: "#222222", opacity: "1", paddingTop: "20px" }}>{i18next.t('eHelp')}</h3>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div><br />
                    <div className="row" style={{ textAlign: "center" }} >
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6">
                            <a className="btn myBtn" href={this.state.authenticated ? "http://assistenzaclienti.aruba.it" : "http://assistenza.aruba.it"} target="_blank" rel="noreferrer">{i18next.t('eBtnPortal')}</a>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div>
                </div>                  //TICKET CLOSED (ROSSO)
            case 12:                         //Ulteriori comunicazioni
                return <div className="container text-centered" >
                    <div className="row">
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{ textAlign: "left", paddingRight: "0px", paddingLeft: "0px" }}>
                            <div className="row">
                                <div className="col-1">
                                    <img src="./document.png" alt="documento" />
                                </div>
                                <div className="col-11">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 style={{ color: "#666666", fontSize: "17px", marginLeft: "10px" }}>{i18next.t('eTopRequest')}</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 style={{ marginLeft: "10px", marginBottom: "0px" }}>{this.state.idTicket}</h4>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <hr />
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>


                    </div>
                    <div className="row" style={{ height: "200px" }}>
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{
                            textAlign: "center",
                            background: "#FDF5E8",
                            border: "1px solid #DDDDDD",
                            borderRadius: "4px",
                            opacity: "1"
                        }}>
                            <img src="./warning.png" style={{ paddingTop: "30px" }} alt="testo" /><br /><br />
                            <h2 style={{ fontSize: "24px", color: "#222222" }}>{i18next.t('eOtherComunication')}</h2>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div>
                    <div className="row" style={{ textAlign: "center" }}>
                        <div className="col-12">
                            <br />
                            <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eLavorazioneMsg1')}</h3>
                            <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eOtherComunicationMsg4')}
                                <a href="assistenza.aruba.it">{i18next.t('ePortal')}</a>,</h3>
                            <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eOtherComunicationMsg5')}</h3>
                        </div>
                    </div><br /><br />

                    <br />
                </div>                  //ULTERIORI COMUNICAZIONI 
            case 99:                         //INVALID AUTH     
                return <div className="container text-centered" >
                    <div className="row">
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{ textAlign: "left", paddingRight: "0px", paddingLeft: "0px" }}>
                            <div className="row">
                                <div className="col-1">
                                    <img src="./document.png" alt="documento" />
                                </div>
                                <div className="col-11">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 style={{ color: "#666666", fontSize: "17px", marginLeft: "10px" }}>{i18next.t('eTopRequest')}</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 style={{ marginLeft: "10px", marginBottom: "0px" }}>&nbsp;</h4>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <hr />
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>


                    </div>
                    <div className="row" style={{ height: "200px" }}>
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{
                            textAlign: "center",
                            background: "#FAE1E4",
                            border: "1px solid #DDDDDD",
                            borderRadius: "4px",
                            opacity: "1"
                        }}>
                            <img src="./alert.png" style={{ paddingTop: "30px" }} alt="testo" /><br /><br />
                            <h2 style={{ fontSize: "24px", color: "#222222" }}>{i18next.t('eComunicationError')}</h2>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div>
                    <div className="row" style={{ textAlign: "center" }}>
                        <div className="col-12">
                            <br />
                            {/*<h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eComunicationError')}</h3>*/}
                            <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eComunicationErrorMsg')}</h3>
                        </div>
                    </div><br /><br />
                    <div className="row">
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                            <hr></hr>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div>
                    <div className="row" style={{ textAlign: "center" }} >
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6">
                            &nbsp;
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div><br />
                    <div className="row" style={{ textAlign: "center" }} >
                        <div className="col-12">
                            <a className="btn myBtn" href={this.state.authenticated ? "http://assistenzaclienti.aruba.it" : "http://assistenza.aruba.it"} target="_blank" rel="noreferrer">{i18next.t('eBtnPortal')}</a>
                        </div>
                    </div>
                </div>
            default:
                return <div className="container text-centered" >
                    <div className="row">
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{ textAlign: "left", paddingRight: "0px", paddingLeft: "0px" }}>
                            <div className="row">
                                <div className="col-1">
                                    <img src="./document.png" alt="documento" />
                                </div>
                                <div className="col-11">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 style={{ color: "#666666", fontSize: "17px", marginLeft: "10px" }}>{i18next.t('eTopRequest')}</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 style={{ marginLeft: "10px", marginBottom: "0px" }}>&nbsp;</h4>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <hr />
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>


                    </div>
                    <div className="row" style={{ height: "200px" }}>
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{
                            textAlign: "center",
                            background: "#FAE1E4",
                            border: "1px solid #DDDDDD",
                            borderRadius: "4px",
                            opacity: "1"
                        }}>
                            <img src="./alert.png" style={{ paddingTop: "30px" }} alt="testo" /><br /><br />
                            <h2 style={{ fontSize: "24px", color: "#222222" }}>{i18next.t('eMissingParameterTitle')}</h2>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div>
                    <div className="row" style={{ textAlign: "center" }}>
                        <div className="col-12">
                            <br />
                            <h3 style={{ fontSize: "17px", color: "#222222", fontStyle: "bold", opacity: "1" }} >{i18next.t('eMissingParameterDescription')}</h3>
                        </div>

                    </div><br /><br />
                    <div className="row">
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6" style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                            <hr></hr>
                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div>
                    <div className="row" style={{ textAlign: "center" }} >
                        <div className="col-3">
                            &nbsp;
                        </div>
                        <div className="col-6">
                            <h3 style={{ fontSize: "18px", color: "#222222", opacity: "1", paddingTop: "20px" }}>{i18next.t('eHelp')}</h3>

                        </div>
                        <div className="col-3">
                            &nbsp;
                        </div>
                    </div><br />
                    <div className="row" style={{ textAlign: "center" }} >
                        <div className="col-12">
                            <a className="btn myBtn" href={this.state.authenticated ? "http://assistenzaclienti.aruba.it" : "http://assistenza.aruba.it"} target="_blank" rel="noreferrer">{i18next.t('eBtnPortal')}</a>
                        </div>
                    </div>
                </div>
        }
    };

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderResult(this.state.result);

        return (
            <div>

                {contents}
            </div>
        );
    }

    async getAuth() {
        if ((this.state.id === null) || (this.state.idTicket === null) || (this.state.id === 0) || (this.state.idTicket === 0)) {

            // PAGINA SENZA PARAMETRI
            // ***********************


            //LOADING,RESULT,RATING,AUTENTICATO
            //0 Link invalido
            //1 Richiesta Chiusa
            //2 Richiesta in Lavorazione
            //3 Richiesta Risolta Confermi
            //4 Richiesta FeedBack
            //5 FeedBack Negativo
            //6 Feedback Positivo
            this.setState({
                loading: false,
                id: this.parametri.id,
                idTicket: this.parametri.idTicket,
                result: 0,
                authenticated: false,
                rating: 0,
                feedback: '',
                token: '',
                motivo: null
            });


            // CHIAMA LA FUNC PER TROVARE LA LINGUA DAL BROWSER
            this.findLanguageFromBrowser(this.lang);

        }
        else {
            const User = {
                id: this.state.id,
                idTicket: this.state.idTicket
            };
            //const responseAuth = await fetch('test/api/authentication', {
            const responseAuth = await fetch('authentication/api/authentication', {
                method: 'POST',
                body: JSON.stringify(User),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    //'Content-type': 'application/json; charset=UTF-8'
                }

            })

            const dataAuth = await responseAuth.text();

            if (dataAuth != null && dataAuth !== "") {

                let ritorno = JSON.parse(dataAuth)

                if (isNotFoundResponse(ritorno)) {

                    this.setState({
                        loading: false,
                        id: this.parametri.id,
                        idTicket: this.parametri.idTicket,
                        result: 9,
                        authenticated: dataAuth.isCustomerPortalAuthenticated,
                        rating: 0,
                        feedback: '',
                        token: dataAuth.token,
                        motivo: null
                    });


                    // CHIAMA LA FUNC PER TROVARE LA LINGUA DAL BROWSER
                    this.findLanguageFromBrowser(this.lang);

                }
                else {

                    if ((ritorno.ticketStatus !== undefined) && (ritorno.ticketStatus !== null)) {
                        switch (ritorno.ticketStatus) {
                            case "New":
                                this.setState({
                                    loading: false,
                                    id: this.parametri.id,
                                    idTicket: this.parametri.idTicket,
                                    result: 8,
                                    authenticated: ritorno.isCustomerPortalAuthenticated,
                                    rating: 0,
                                    feedback: '',
                                    token: ritorno.token,
                                    motivo: null
                                });
                                break;
                            case "WaitingUser":
                                this.setState({
                                    loading: false,
                                    id: this.parametri.id,
                                    idTicket: this.parametri.idTicket,
                                    result: 8,
                                    authenticated: ritorno.isCustomerPortalAuthenticated,
                                    rating: 0,
                                    feedback: '',
                                    token: ritorno.token,
                                    motivo: null
                                });
                                break;
                            case "WorkingOn":
                                this.setState({
                                    loading: false,
                                    id: this.parametri.id,
                                    idTicket: this.parametri.idTicket,
                                    result: 2,
                                    authenticated: ritorno.isCustomerPortalAuthenticated,
                                    rating: 0,
                                    feedback: '',
                                    token: ritorno.token,
                                    motivo: null
                                });
                                break;
                            case "ProposedSolution":
                                this.setState({
                                    loading: false,
                                    id: this.parametri.id,
                                    idTicket: this.parametri.idTicket,
                                    result: 10,
                                    authenticated: ritorno.isCustomerPortalAuthenticated,
                                    rating: 0,
                                    feedback: '',
                                    token: ritorno.token,
                                    motivo: null
                                });
                                break;
                            case "Closed":
                                this.setState({
                                    loading: false,
                                    id: this.parametri.id,
                                    idTicket: this.parametri.idTicket,
                                    result: 1,
                                    authenticated: ritorno.isCustomerPortalAuthenticated,
                                    rating: 0,
                                    feedback: '',
                                    token: ritorno.token,
                                    motivo: null
                                });
                                break;
                            case "Resolved":
                                this.setState({
                                    loading: false,
                                    id: this.parametri.id,
                                    idTicket: this.parametri.idTicket,
                                    result: 1,
                                    authenticated: ritorno.isCustomerPortalAuthenticated,
                                    rating: 0,
                                    feedback: '',
                                    token: ritorno.token,
                                    motivo: null
                                });
                                break;
                            case "Skipped": // = a Closed
                                this.setState({
                                    loading: false,
                                    id: this.parametri.id,
                                    idTicket: this.parametri.idTicket,
                                    result: 1,
                                    authenticated: ritorno.isCustomerPortalAuthenticated,
                                    rating: 0,
                                    feedback: '',
                                    token: ritorno.token,
                                    motivo: null
                                });
                                break;
                            case "InternalClosing": // = a Closed
                                this.setState({
                                    loading: false,
                                    id: this.parametri.id,
                                    idTicket: this.parametri.idTicket,
                                    result: 1,
                                    authenticated: ritorno.isCustomerPortalAuthenticated,
                                    rating: 0,
                                    feedback: '',
                                    token: ritorno.token,
                                    motivo: null
                                });
                                break;
                            case "CustomerClosing": // = a Closed
                                this.setState({
                                    loading: false,
                                    id: this.parametri.id,
                                    idTicket: this.parametri.idTicket,
                                    result: 1,
                                    authenticated: ritorno.isCustomerPortalAuthenticated,
                                    rating: 0,
                                    feedback: '',
                                    token: ritorno.token,
                                    motivo: null
                                });
                                break;
                            case "AwaitingExternalProvider":  // = a WorkingOn
                                this.setState({
                                    loading: false,
                                    id: this.parametri.id,
                                    idTicket: this.parametri.idTicket,
                                    result: 2,
                                    authenticated: ritorno.isCustomerPortalAuthenticated,
                                    rating: 0,
                                    feedback: '',
                                    token: ritorno.token,
                                    motivo: null
                                });
                                break;
                            case "Appointment": // = a WorkingOn
                                this.setState({
                                    loading: false,
                                    id: this.parametri.id,
                                    idTicket: this.parametri.idTicket,
                                    result: 2,
                                    authenticated: ritorno.isCustomerPortalAuthenticated,
                                    rating: 0,
                                    feedback: '',
                                    token: ritorno.token,
                                    motivo: null
                                });
                                break;
                            case "Planned": // = a WorkingOn
                                this.setState({
                                    loading: false,
                                    id: this.parametri.id,
                                    idTicket: this.parametri.idTicket,
                                    result: 2,
                                    authenticated: ritorno.isCustomerPortalAuthenticated,
                                    rating: 0,
                                    feedback: '',
                                    token: ritorno.token,
                                    motivo: null
                                });
                                break;
                            case "AwaitingProvider": // = a WorkingOn
                                this.setState({
                                    loading: false,
                                    id: this.parametri.id,
                                    idTicket: this.parametri.idTicket,
                                    result: 2,
                                    authenticated: ritorno.isCustomerPortalAuthenticated,
                                    rating: 0,
                                    feedback: '',
                                    token: ritorno.token,
                                    motivo: null
                                });
                                break;
                            case "AwaitingInternalDepartment": // = a WorkingOn
                                this.setState({
                                    loading: false,
                                    id: this.parametri.id,
                                    idTicket: this.parametri.idTicket,
                                    result: 2,
                                    authenticated: ritorno.isCustomerPortalAuthenticated,
                                    rating: 0,
                                    feedback: '',
                                    token: ritorno.token,
                                    motivo: null
                                });
                                break;
                            default:
                                this.setState({
                                    loading: false,
                                    id: this.parametri.id,
                                    idTicket: this.parametri.idTicket,
                                    result: 0,
                                    authenticated: false,
                                    rating: 4,
                                    feedback: '',
                                    token: dataAuth.token,
                                    motivo: null
                                });
                        }

                        // CHIAMA LA FUNC PER TROVARE LA LINGUA DAL TICKET
                        this.findLanguageFromTicket(this.lang, ritorno.cultureName);

                        return;
                    }
                    else {

                        //Ticket valido in base al ritorno faccio le operazioni
                        this.setState({
                            loading: false,
                            id: this.parametri.id,
                            idTicket: this.parametri.idTicket,
                            result: getErrorResult(ritorno),
                            authenticated: ritorno.isCustomerPortalAuthenticated,
                            rating: 0,
                            feedback: '',
                            token: dataAuth.token,
                            motivo: null
                        });


                        // CHIAMA LA FUNC PER TROVARE LA LINGUA DAL BROWSER

                        this.findLanguageFromBrowser(this.lang);

                    }
                }
            }
            else {
                this.setState({
                    loading: false,
                    id: this.parametri.id,
                    idTicket: this.parametri.idTicket,
                    result: 0,
                    authenticated: false,
                    rating: 0,
                    feedback: '',
                    token: '',
                    motivo: null
                });


                // CHIAMA LA FUNC PER TROVARE LA LINGUA DAL BROWSER
                this.findLanguageFromBrowser(this.lang);

            }
        }
    };


    findLanguageFromBrowser = (lang) => {

        // recupera la lingua del browser
        lang = navigator.language || navigator.userLanguage;

        // se la lingua del browser � pi� lunga di 2 caratteri
        if (lang.length > 2) {
            lang = lang.substr(0, 2); // prende solo i primi 2 caratteri
        }

        // se la lingua del browser NON ESISTE tra le lingue supportate
        if (this.langObj[lang] === undefined) {
            lang = 'en'; // setta la lingua di default
        }

        // traduce
        i18next.changeLanguage(lang);

        // setta la lingua selezionata del menu in cima a destra
        document.getElementsByClassName('selected-language')[0].innerHTML =
            '&nbsp;' + this.langObj[lang];
    }


    findLanguageFromTicket = (lang, ritornoCultureName) => {

        // se esiste il valore della lingua nel campo CULTURE 
        // restituito dalla WebApi
        if (ritornoCultureName !== undefined) {

            // lo setta
            lang = ritornoCultureName;

            // traduce
            i18next.changeLanguage(lang);

            // setta la lingua selezionata del menu in cima a destra
            document.getElementsByClassName('selected-language')[0].innerHTML =
                '&nbsp;' + this.langObj[lang];

        } else {
            // se non esiste il valore della lingua nel campo CULTURE
            // chiama la func che usa la lingua del browser
            this.findLanguageFromBrowser(lang);
        }
    }



    confirmClose = async (e) => {

        
        const myRating = this.state.rating;
        const myFeedBack = this.state.feedback;
        const myTicket = this.state.idTicket;
        const myToken = this.state.token;
        const myId = this.state.id

        var feedbackData = {
            "Comment": myFeedBack,
            "Rating": myRating,
            "Id": myTicket
        }


        if (myRating === 0) {
            alert(i18next.t('eSelezionaStelle')); // "Aggiungi una valutazione sull'assistenza ricevuta."
            return;
        }
        //if ((myRating < 4) && (myFeedBack.length < 5)) {
        //    alert(i18next.t('eCompilaCommentoFeeback')); // "Inserisci almeno 5 caratteri nel commento, per poter inviare la valutazione."
        //}
        else
        {
            //// DA REMMARE: TEST PER STELLE < 1 E > 6
            //// **************************************
            //var feedbackData = {
            //    "Comment": myFeedBack,
            //    "Rating": 6,
            //    "Id": myTicket
            //}


            var token = 'Bearer ' + myToken;
            const config = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify(feedbackData)
            }
            //const getUrl = window.location;
            //var baseUrl = getUrl.protocol + "//" + getUrl.host + "/rest/api/authentication/set-feedback";
            //const responseAuth = await fetch(baseUrl, config);
            const responseAuth = await fetch('authentication/api/set-feedback', config);
            if ((responseAuth.status === 404) || (responseAuth.status === 500)) {
                this.setState({
                    loading: false,
                    id: this.parametri.id,
                    idTicket: this.parametri.idTicket,
                    result: 9,
                    authenticated: this.state.authenticated,
                    rating: myRating,
                    feedback: myFeedBack,
                    token: '',
                    motivo: null
                });
                return
            } else {

                this.setState({
                    loading: false,
                    id: myId,
                    idTicket: myTicket,
                    result: myRating < 4 ? 5 : 6,   //Se il rating � minore di 4 va al negativo se no va al positivo
                    authenticated: this.state.authenticated,
                    rating: myRating,
                    feedback: myFeedBack,
                    token: myToken,
                    motivo: null
                });
            }
        }
    }

    feedbackChanged = (e) => {
        const rating = this.state.rating
        const myTicket = this.state.idTicket;

        this.setState({
            loading: false,
            id: this.id,
            idTicket: myTicket,
            result: this.state.result,
            authenticated: this.state.authenticated,
            rating: rating,
            feedback: e.target.value,
            token: this.state.token,
            motivo: null
        });
    }


    sendFeedBack = async (e) => {
        e.preventDefault();

        const myRating = this.state.rating;
        const myFeedBack = this.state.feedback;
        const myTicket = this.state.idTicket;
        const myToken = this.state.token;
        const myId = this.state.id;

        this.setState({
            loading: false,
            id: myId,
            idTicket: myTicket,
            result: 4,
            authenticated: this.state.authenticated,
            rating: myRating,
            feedback: myFeedBack,
            token: this.token,
            motivo: null
        });
        //window.location.assign('/counter');

        if (myRating === 0) {
            alert(i18next.t('eSelezionaStelle')); // "Aggiungi una valutazione sull'assistenza ricevuta."
            return;
        }
        //if ((myRating < 4) && (myFeedBack.length < 5)) {
        //    alert(i18next.t('eCompilaCommentoFeeback')); // "Inserisci almeno 5 caratteri nel commento, per poter inviare la valutazione."
        //}
        else {
            var token = 'Bearer ' + myToken;
            var feedbackData = {
                "Comment": myFeedBack,
                "Rating": myRating,
                "Id": myTicket
            }
            const config = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify(feedbackData)
            }

            const responseAuth = await fetch('authentication/api/set-feedback', config);
            if ((responseAuth.status === 404) || (responseAuth.status === 500)) {
                this.setState({
                    loading: false,
                    id: this.parametri.id,
                    idTicket: this.parametri.idTicket,
                    result: 9,
                    authenticated: this.state.authenticated,
                    rating: myRating,
                    feedback: '',
                    token: '',
                    motivo: null
                });
                return
            } else {
                if (myRating > 3) {

                    //Faccio la chiamata e setto il result a 6
                    this.setState({
                        loading: false,
                        id: this.parametri.id,
                        idTicket: this.parametri.idTicket,
                        result: 6,
                        authenticated: false,
                        rating: myRating,
                        feedback: this.feedback,
                        token: this.token,
                        motivo: null
                    });
                } else {
                    this.setState({
                        loading: false,
                        id: this.parametri.id,
                        idTicket: this.parametri.idTicket,
                        result: 5,
                        authenticated: false,
                        rating: myRating,
                        feedback: this.feedback,
                        token: this.token,
                        motivo: null
                    });
                }
            }
            this.renderResult(myRating);
        }
    }

    setmotivo = (e) => {
        const myRating = this.state.rating;
        const myFeedBack = this.state.feedback;
        const myTicket = this.state.idTicket;

        const token = this.state.token;
        this.setState({
            loading: false,
            id: this.id,
            idTicket: myTicket,
            result: this.state.result,
            authenticated: this.state.authenticated,
            rating: myRating,
            feedback: myFeedBack,
            token: token,
            motivo: e.target.value
        });
    }

    anticipa = async (e) => {
        e.preventDefault();
        const myRating = this.state.rating;
        const myFeedBack = this.state.feedback;
        const myTicket = this.state.idTicket;
        const motivo = this.state.motivo;
        const myToken = this.state.token;
        this.setState({
            loading: false,
            id: this.id,
            idTicket: myTicket,
            result: this.state.result,
            authenticated: this.state.authenticated,
            rating: myRating,
            feedback: myFeedBack,
            token: myToken,
            motivo: motivo
        });
        //window.location.assign('/counter');


        var tokenStr = 'Bearer ' + myToken;
        var feedbackData = {
            "type": 0,
            "name": motivo,
            "code": motivo
        }
        const config = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': tokenStr
            },
            body: JSON.stringify(feedbackData)
        }

        const responseAuth = await fetch('authentication/api/customer-closing', config)
        if ((responseAuth.status === 404) || (responseAuth.status === 500)) {
            this.setState({
                loading: false,
                id: this.parametri.id,
                idTicket: this.parametri.idTicket,
                result: 9,
                authenticated: false,
                rating: myRating,
                feedback: myFeedBack,
                token: myToken,
                motivo
            });
            return
        } else {
            this.setState({
                loading: false,
                id: this.parametri.id,
                idTicket: this.parametri.idTicket,
                result: 11,
                authenticated: this.state.authenticated,
                rating: myRating,
                feedback: myFeedBack,
                token: myToken,
                motivo
            });
        }
        this.renderResult(myRating);
    }

    ratingChanged = (rating) => {
        this.setState({
            loading: false,
            id: this.state.id,
            idTicket: this.state.idTicket,
            result: 10,
            authenticated: this.state.authenticated,
            rating: rating,
            feedback: this.state.feedback,
            token: this.state.token
        });
    }


}

function isNotFoundResponse(ritorno) {
    return (ritorno.title != null) && (ritorno.title === "Not Found");
}

function getErrorResult(ritorno, response) {
    let error = ritorno.error
    if (error === 0 || error === 1 || error === 2) return error

    if (error === 99) return 7

    if (error !== undefined && error.errors) {
        return error.status === 400 ? null : 9
    }

    return 9
}

import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import Home from "./components/Home";
import { withTranslation } from 'react-i18next';

const MyComponent = withTranslation()(Home)
//import { withRouter } from "./components/withRouter";

const AppRoutes = [
    {
        index: true,
        element: <MyComponent />
    },
    {
        path: '/',
        element: <Home />
    },
    {
        path: '/home',
        element: <Home />
    },
  {
    path: '/counter',
    element: <Counter />
  },
  {
    path: '/fetch-data',
    element: <FetchData />
  }
];

export default AppRoutes;

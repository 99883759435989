import React, { Component } from 'react';


export class BottomFooter extends Component {
  

  constructor (props) {
    super(props);

    var dt = new Date();
    var year = dt.getFullYear();
    
    this.state = {
          copyright: "Aruba S.p.A. - P.I. 01573850516",
          resto: " - All rights reserved",
          anno: year
    };
  }

  //toggleNavbar () {
  //  this.setState({
  //    collapsed: !this.state.collapsed
  //  });
  //}

  render() {
      return (
          //PRIMA ERA FOOTER FISSATO MA DAVA PROBLEMI SUL RESPONSIVE: <footer className="footer fixed-bottom">
          <footer className="">
              <div className="container text-centered" style={{ textAlign: "center" }}><hr />
                  <span className="text-muted" >
                      {this.state.copyright}
                  </span> 
              </div>
              <div className="container text-centered" style={{ textAlign: "center" }}>
                  <span className="text-muted" >
                      &copy; {this.state.anno}{this.state.resto}
                      </span>
              </div>
              <div className="container text-centered" style={{ textAlign: "center" }}>
                  <span className="text-muted" >
                      <a href="https://www.aruba.it/cookie-policy.aspx" target="_blank" rel="noreferrer">Informativa sull'uso dei cookie</a>
                     </span>
              </div>
              <div className="container text-centered" style={{ textAlign: "center" }}>
                  <span className="text-muted" >
                     <a href="https://www.aruba.it/documents/tc-files/it/11_it_privacy_policy_aruba_spa.aspx
                      " target="_blank" rel="noreferrer">Privacy Policy</a></span>
              </div>
        </footer>
    );
  }
}

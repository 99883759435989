module.exports =
    global.config = {
    parameters: getParams()
};

function getParams() {
    const searchParams = new URLSearchParams(window.location.search);
    let id = getIdFromUrlParams(searchParams)
    let idTicket = getIdTicketFromUrlParams(searchParams);
    return {
        id: id,
        idTicket: idTicket
    };
}

function getIdTicketFromUrlParams(searchParams) {
    return getParamFromUrl(searchParams, ["idticket", "Idticket", "IdTicket", "idTicket", "IDTICKET"])
}

function getIdFromUrlParams(searchParams) {
    return getParamFromUrl(searchParams, ["id", "Id", "iD", "ID"])
}

function getParamFromUrl(searchParams, availableParams) {
    if (!availableParams || !searchParams) return null;

    for (let i = 0; i < availableParams.length - 1; i++) {
        let id = searchParams.get(availableParams[i]);
        if (id) return id;
    }
    return null;
}

import React, { useState, useEffect, useRef } from 'react';

const Star = ({ filled, onClick, onMouseEnter, onMouseLeave, onTouchStart, onTouchEnd, size, color, unselectedColor }) => (
    <svg
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={filled ? color : unselectedColor}
        stroke={filled ? color : 'none'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ cursor: 'pointer', touchAction: 'none' }}
    >
        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
    </svg>
);

const LFStarRating = ({ onChange, initialRating = 0, size = 30, color = '#ffd700', unselectedColor = '#d3d3d3', starSpacing = 10 }) => {
    const [rating, setRating] = useState(initialRating);
    const [hover, setHover] = useState(0);
    const [isVertical, setIsVertical] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        setRating(initialRating);
    }, [initialRating]);

    useEffect(() => {
        const checkOrientation = () => {
            if (containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth;
                const totalStarWidth = size * 5 + starSpacing * 4;
                setIsVertical(containerWidth < totalStarWidth);
            }
        };

        checkOrientation();
        window.addEventListener('resize', checkOrientation);
        return () => window.removeEventListener('resize', checkOrientation);
    }, [size, starSpacing]);

    const handleRatingChange = (newRating) => {
        setRating(newRating);
        if (onChange) {
            onChange(newRating);
        }
    };

    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: isVertical ? 'column' : 'row',
        alignItems: 'center',
        width: '100%',
    };

    const starStyle = {
        margin: isVertical ? `${starSpacing / 2}px 0` : `0 ${starSpacing / 2}px`,
    };

    return (
        <div ref={containerRef} style={containerStyle}>
            {[...Array(5)].map((_, index) => {
                const starValue = index + 1;
                return (
                    <div key={starValue} style={starStyle}>
                        <Star
                            filled={starValue <= (hover || rating)}
                            onClick={() => handleRatingChange(starValue)}
                            onMouseEnter={() => setHover(starValue)}
                            onMouseLeave={() => setHover(0)}
                            onTouchStart={() => setHover(starValue)}
                            onTouchEnd={() => {
                                handleRatingChange(starValue);
                                setHover(0);
                            }}
                            size={size}
                            color={color}
                            unselectedColor={unselectedColor}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default LFStarRating;